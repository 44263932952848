<template>
  <section>
    <ag-grid-table
      :debug="true"
      ref="grid-historico-lineas-productivas"
      :configUrl="historicoLineasProductivasConfig"
      :dataUrl="historicoLineasProductivasData"
      :editUrl="historicoLineasProductivasEdit"
      :actions="actions"
    >
    </ag-grid-table>
  </section>
</template>

<script>

import { VueAutosuggest } from 'vue-autosuggest'

import CosechaUrgente from "@/views/clients/camanchaca/components/CosechaUrgente";
import Screenshot from "@/views/clients/camanchaca/components/Screenshot";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";

import Vue from 'vue'

import
{
  BRow,
  BCol,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormDatepicker,
  BCardTitle,
  BCardSubTitle,
  VBTooltip,
  VBPopover
} from "bootstrap-vue";
import vSelect from 'vue-select';
import axios from "@axios";


import useApiServices from '@/services/useApiServices.js';
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'


export default {

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  props: {
  },

  data()
  {




    return {


      historicoLineasProductivasConfig: useApiServices.historicoLineasProductivasConfig,
      historicoLineasProductivasData: useApiServices.historicoLineasProductivasData,
      historicoLineasProductivasEdit: useApiServices.historicoLineasProductivasEdit,

      actions: [
        { name: "Eliminar", icon: "Trash2Icon", action: this.actionEliminar },
      ],






    };
  },
  components: {
    BRow,
    BCol,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    BCardBody,
    CosechaUrgente,
    Screenshot,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    ToastificationContent,
    vSelect,
    BCardTitle,
    BCardSubTitle,
    VBPopover,
    VBTooltip,
    VueAutosuggest,
    BrunaForm,
    AgGridTable,

  },

  created()
  {


  },

  mounted()
  {




  },


  methods: {

    actionEliminar(params)
    {

      let item_id = params.data.id

      this.$bvModal.msgBoxConfirm('¿Quieres eliminar la linea?', {
        okTitle: 'Confirmar',
        cancelTitle: 'Cancelar'
      })
        .then(value =>
        {

          if (value)
          {
            console.log(item_id)

            useApiServices.postLineasDelete({ id: item_id })
              .then((response) =>
              {
                params.api.refreshServerSideStore({ route: [], purge: false });


              });

          }





        })
        .catch(err =>
        {
          // An error occurred
        })




    },


    refreshRows()
    {
      this.$refs['grid-historico-lineas-productivas'].refreshRows()
    }



  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss">
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>