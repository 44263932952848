<template>
  <section>
    <BrunaForm
      ref="nuevaLineaRef"
      v-bind:fields="this.nueva_linea.fields"
      :card="this.nueva_linea.card"
      @change="nuevaLineaChange"
    ></BrunaForm>

    <b-card>
      <b-card-body>
        <b-button
          variant="primary"
          class="mt-4 float-right"
          @click="save"
          size="lg"
          >Guardar</b-button
        >
      </b-card-body>
    </b-card>
  </section>
</template>

<script>

import { VueAutosuggest } from 'vue-autosuggest'

import CosechaUrgente from "@/views/clients/camanchaca/components/CosechaUrgente";
import Screenshot from "@/views/clients/camanchaca/components/Screenshot";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";

import Vue from 'vue'

import
{
  BRow,
  BCol,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormDatepicker,
  BCardTitle,
  BCardSubTitle,
  VBTooltip,
  VBPopover
} from "bootstrap-vue";
import vSelect from 'vue-select';
import axios from "@axios";


import useApiServices from '@/services/useApiServices.js';

export default {

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  props: {
    forecast: {
      type: Object,
      default: () => null
    }
  },

  data()
  {

    let capacidades_mensuales = []


    for (let mes = 1; mes <= 12; mes++)
    {
      capacidades_mensuales.push(

        {
          label: "Capacidad M" + mes,
          type: "input",
          id: 'capacidad_m' + mes,
          rules: 'required|numeric2',
          tooltip: null,
          format: {
            xs: 2,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 2
          },
          value: null
        },
      );

    }


    return {



      nueva_linea: {
        card: {
          title: "Crear Nueva Linea",
          subtitle: "",
          tooltip: ""
        },
        fields: [


          {
            label: "Estado Linea",
            type: "select",
            id: 'estado_linea',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "Activada", text: "Activada" },
              { value: "Pausada", text: "Pausada" },

            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },


          {
            label: "Nombre Linea",
            type: "input",
            id: 'nombre_linea',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null,
            show: true
          },

        



          {
            label: "Etapa Linea",
            type: "select",
            id: 'etapa_linea',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "Primaria", text: "Primaria" },
              { value: "Secundaria", text: "Secundaria" },

            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },


          {
            label: "Tipo Linea",
            type: "select",
            id: 'tipo_linea',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opcion" },
              //{ value: "Porciones/Pieza", text: "Porciones/Pieza" },
              { value: "Tipo Linea 1", text: "Tipo Linea 1" },
              { value: "Tipo Linea 2", text: "Tipo Linea 2" },
              { value: "Tipo Linea 3", text: "Tipo Linea 3" },
              { value: "Tipo Linea 4", text: "Tipo Linea 4" },
              { value: "Tipo Linea 5", text: "Tipo Linea 5" },
              { value: "Tipo Linea 6", text: "Tipo Linea 6" },
              { value: "Tipo Linea 7", text: "Tipo Linea 7" },
              { value: "Tipo Linea 8", text: "Tipo Linea 8" },
              { value: "Otro", text: "Otro" },


            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },


          {
            label: "Ubicacion",
            type: "select",
            id: 'ubicacion',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opcion" },

              { value: "Ubicacion 1", text: "Ubicacion 1" },
              { value: "Ubicacion 2", text: "Ubicacion 2" },
              { value: "Ubicacion 3", text: "Ubicacion 3" },
              { value: "Ubicacion 4", text: "Ubicacion 4" },
              { value: "Ubicacion 5", text: "Ubicacion 5" },
              { value: "Ubicacion 6", text: "Ubicacion 6" },
              { value: "Otro", text: "Otro" },

            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Propia",
            type: "select",
            id: 'propia',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "Si", text: "Si" },
              { value: "No", text: "No" },

            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Unidad Periodo",
            type: "select",
            id: 'unidad_periodo',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "Unidad Periodo 1", text: "Unidad Periodo 1" },
              { value: "Unidad Periodo 2", text: "Unidad Periodo 2" },
              { value: "Unidad Periodo 3", text: "Unidad Periodo 3" },
              { value: "Unidad Periodo 4", text: "Unidad Periodo 4" },
              { value: "Unidad Periodo 5", text: "Unidad Periodo 5" },


            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          ...capacidades_mensuales,


          {
            label: "Available on Weekends",
            type: "checkbox",
            id: 'available_on_weekends',
            rules: 'required',
            format: {
              xs: 2,
              sm: 2,
              md: 2, 
              lg: 2,
              xl: 2
            },
            value: false,
          },

          {
            label: "Available on Holidays",
            type: "checkbox",
            id: 'available_on_holidays',
            rules: 'required',
            format: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2
            },
            value: false,
          },  



        ]
      },




    };
  },
  components: {
    BRow,
    BCol,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    BCardBody,
    CosechaUrgente,
    Screenshot,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    ToastificationContent,
    vSelect,
    BCardTitle,
    BCardSubTitle,
    VBPopover,
    VBTooltip,
    VueAutosuggest,
    BrunaForm,
  },

  created()
  {


  },

  mounted()
  {




  },


  methods: {



    formatData()
    {

      return {
        nueva_linea: this.nueva_linea.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
      }


    },


    createNuevaLinea()
    {



      useApiServices.postLineasCreate(this.formatData(), this)
        .then((response) =>
        { 

          this.$emit('nueva-linea-agregada');

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Carga Exitosa`,
              icon: "UploadIcon",
              variant: "success",
              html: `La linea se ha creado exitosamente. Recuerde asignar esta nueva linea productiva a los respectivos SKUs`,
            },
          });


        })
        .catch((error) =>
        {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ocurrio un Error`,
              icon: "UploadIcon",
              variant: "danger",
              html: `Ha ocurrido un error al guardar la nueva linea</b>.`,
            },
          });

        });





    },


    save()
    {

      console.log(JSON.stringify(this.formatData(), null, 2))

      let self = this;

      Promise.all([
        this.$refs.nuevaLineaRef.validate(),


      ]).then(([nuevaLineaValid]) =>
      {

          if(nuevaLineaValid)
          {
              self.createNuevaLinea()
          }

        //console.log({ demandDetailValid, buyerValid, orderValid, skuValid, priceProgramValid, priceSpotValid })



      })


    },



    findFieldByName(fields, name)
    {

      return fields.find(field => field.id == name)

    },





    nuevaLineaChange(data)
    {
    
      console.log(data);

      console.log(this.formatData())

    },


  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss">
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>