<template>
  <section>
    <b-tabs v-model="tabIndex">
      <!-- Tab Carga de Archivos -->

      <b-tab title="Lineas Productivas">
        <historico-lineas-productivas
          ref="historico-lineas-productivas"
        ></historico-lineas-productivas>
      </b-tab>

      <!-- Tab Historico de Carga -->

      <b-tab title="Ingreso Nueva Linea">
        <nueva-linea-form
          @nueva-linea-agregada="refreshHistoricoLineasProductivas"
        ></nueva-linea-form>
      </b-tab>
    </b-tabs>
  </section>
</template>
<script>
import
{
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";



import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'

import NuevaLineaForm from "./NuevaLineaForm.vue";
import HistoricoLineasProductivas from "./HistoricoLineasProductivas.vue";


export default {
  data()
  {

    let activeTabName = this.$route.params.active_tab_name;
    let tabIndex = 0;

    if (activeTabName == "Lineas Productivas")
    {
      tabIndex = 0
    }


    if (activeTabName == "Ingreso Nueva Linea")
    {
      tabIndex = 1
    }


    return {

      tabIndex: tabIndex



    };




  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    //GoodTableBasic,
    BFormInvalidFeedback,
    BFormGroup,
    AgGridTable,
    NuevaLineaForm,
    HistoricoLineasProductivas
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted()
  {

  },

  methods: {


    refreshHistoricoLineasProductivas()
    {

      this.$refs['historico-lineas-productivas'].refreshRows()

    }



  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

